import { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'

export default function SectionThree() {
  const [activeIndex, setActiveIndex] = useState(0)

  const products = [
    {
      src: '../../images/lp/babolatgold1.webp',
      title: 'Babolat Gold Championship X3',
      href: 'https://loja.clubedotennis.com.br/products/bola-gold-championship-x3-amarelo',
      originalPrice: 'R$ 69,00',
      discountedPrice: 'R$ 59,00',
    },
    {
      src: '../images/lp/2944537-1200-auto.webp',
      title: 'Overgrip Pro Tour x3',
      href: 'https://loja.clubedotennis.com.br/products/overgrip-pro-tour-x3-branco-u?_pos=1&_psq=over&_ss=e&_v=1.0',
      originalPrice: 'R$ 59,00',
      discountedPrice: 'R$ 49,00',
    },
    {
      src: '../images/lp/raqueteira_babolat_rh12_pure_aero_rafa_azul_ama_rosa.webp',
      title: 'Raqueteira RH6 Aero Rafa',
      href: 'https://loja.clubedotennis.com.br/products/raqueteira-rh6-pure-aero-rafa-azul-ama-rosa',
      originalPrice: 'R$ 1.399,00',
      discountedPrice: 'R$ 999,00',
    },
  ]

  return (
    <Container className="my-2" id="SectionThree">
      <Row>
        <Col xs="12" md="6" className="mt-5 my-md-auto px-4">
          <h3 className="display-5 fw-bold mb-4">Loja CdT</h3>
          <div className="fw-light fs-3 fs-md-4 mb-4">
            <p>
              Nossos membros podem comprar produtos e equipamentos das melhores
              marcas com preços exclusivos.
            </p>
            <p>
              Na nossa loja você encontra tudo o que precisa para elevar o seu
              jogo!
            </p>
          </div>
          <a
            className="text-center d-inline-flex align-items-center btn btn-lg fs-4 text-white bg-tertiary"
            href="https://loja.clubedotennis.com.br/"
          >
            Ir para a loja
            <i className="bx bx-chevron-right" style={{ fontSize: 20 }} />
          </a>
        </Col>
        <Col xs="12" md="6" className="px-5">
          <div
            id="carouselProducts"
            className="carousel slide"
            data-bs-ride="carousel"
            data-bs-interval="false"
          >
            <div className="carousel-inner">
              {products.map((item, index) => {
                const isActive = index === activeIndex
                return (
                  <div
                    key={index}
                    className={`carousel-item d-flex align-items-center overflow-hidden ${isActive ? 'active' : ''}`}
                    style={{
                      opacity: isActive ? 1 : 0,
                      transition: 'transform 0.5s ease, opacity 0.5s ease',
                      borderRadius: '10px',
                    }}
                  >
                    <img
                      src={item.src}
                      alt={item.title}
                      className="img-fluid border"
                      style={{ maxHeight: '300px' }}
                    />
                    {isActive && (
                      <a className="carousel-text mt-3" href={item.href}>
                        <h6>{item.title}</h6>
                        <p className="justify-content-center">
                          <span>{item.originalPrice}</span>
                          <span>{item.discountedPrice}</span>
                        </p>
                      </a>
                    )}
                  </div>
                )
              })}
            </div>

            <button
              className="carousel-control-prev fw-bold"
              type="button"
              data-bs-target="#carouselProducts"
              data-bs-slide="prev"
              onClick={() =>
                setActiveIndex(
                  activeIndex === 0 ? products.length - 1 : activeIndex - 1,
                )
              }
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselProducts"
              data-bs-slide="next"
              onClick={() =>
                setActiveIndex(
                  activeIndex === products.length - 1 ? 0 : activeIndex + 1,
                )
              }
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>

            {/* Carousel Indicators */}
            <ul
              className="carousel-indicators"
              style={{ marginBottom: '-20px', listStyle: 'none' }}
            >
              {products.map((_, index) => (
                <li
                  key={index}
                  className={index === activeIndex ? 'active' : ''}
                  style={{
                    backgroundColor: index === activeIndex ? '#015c36' : '#ddd',
                    borderRadius: '50%',
                    width: '10px',
                    height: '10px',
                    margin: '0 5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setActiveIndex(index)}
                />
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
