import { Link } from '@remix-run/react'

import { Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap'
import { useToggle } from '#app/hooks/useToggle.ts'

export default function NavBar() {
  const [isOpen, toggleMenu] = useToggle(false)
  const links = [
    { title: 'Como funciona', url: '#como-funciona' },
    { title: 'Contato', url: '#contato' },
    { title: 'Loja', url: 'https://loja.clubedotennis.com.br' },
  ]

  return (
    <Navbar
      expand="md"
      className="navbar-transparent"
      style={{
        padding: '10px 0',
        position: 'absolute',
        width: '100%',
        zIndex: 10,
      }}
    >
      <div className="logo">
        <Link to="/">
          <img
            src="../images/logo-white.svg"
            alt="Clube do Tennis"
            style={{ height: '120px', padding: '16px' }}
          />
        </Link>
      </div>
      <div>
        <Link
          to="/acessar"
          style={{
            border: 'none',
            fontSize: '1rem',
          }}
          className="btn text-white d-lg-none d-md-none bg-tertiary"
        >
          Acessar
        </Link>
        <NavbarToggler
          onClick={toggleMenu}
          style={{ zIndex: 15, color: 'white' }}
          className="navbar-light"
          color="white"
        />
      </div>
      <Collapse
        isOpen={isOpen}
        navbar
        className="d-md-none dropdown-menu-custom"
      >
        <Nav className="me-auto" navbar>
          {links.map(link => (
            <NavItem key={link.title}>
              <Link
                to={link.url}
                className="nav-link"
                style={{
                  fontSize: '1rem',
                }}
              >
                {link.title}
              </Link>
            </NavItem>
          ))}
        </Nav>
        <div className="d-flex align-items-center">
          <NavItem style={{ listStyleType: 'none', paddingBlock: '8px' }}>
            <Link
              to="/cadastre-se"
              className="nav-link"
              style={{
                fontSize: '1rem',
              }}
            >
              Crie sua conta
            </Link>
          </NavItem>
          <NavItem style={{ listStyleType: 'none', marginLeft: '15px' }}>
            <Link
              to="/acessar"
              className="btn text-white d-none d-md-block bg-tertiary"
              style={{
                border: 'none',
                marginLeft: '14px',
                fontSize: '1rem',
              }}
            >
              Acessar
            </Link>
          </NavItem>
        </div>
      </Collapse>
    </Navbar>
  )
}
